import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import get from "lodash/get"
import Map from "../components/map"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PoiTemplate = ({ data }) => {
  const meta = get(data, "site.siteMetadata")
  const title = get(data, "contentfulPoi.title")
  const type = get(data, "contentfulPoi.type")
  const body = get(data, "contentfulPoi.body")
  const image = get(data, "contentfulPoi.image")
  const location = get(data, "contentfulPoi.location")
  const html = get(body, "childMarkdownRemark.html")
  const position = location && location.lat && [location.lat, location.lon]
  const mapLocations = [
    { title, position },
    { title: meta.title, position: meta.house },
  ]

  return (
    <>
      <SEO title={title} keywords={title} />
      <Layout>
        <article className="flex flex-col space-y-4 md:flex-row md:space-x-8 lg:space-x-24 md:space-y-0">
          <div className="w-full space-y-4 md:w-1/2">
            <div className="flex items-center md:items-start md:flex-col">
              <h1 className="inline-block m-0">{title}</h1>
              {type && (
                <span className="inline-block px-4 ml-4 text-xs text-center lowercase bg-gray-200 border rounded-full md:ml-0 md:mt-4">
                  {type}
                </span>
              )}
            </div>
            <div className="html" dangerouslySetInnerHTML={{ __html: html }} />
          </div>

          <div className="w-full space-y-8 md:w-1/2">
            {image && (
              <div className="w-full">
                <Img fluid={image.fluid} />
              </div>
            )}
            {position && typeof window !== "undefined" && (
              <Map locations={mapLocations} />
            )}
          </div>
        </article>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query PoiQuery($url: String!) {
    site {
      siteMetadata {
        house
        title
      }
    }
    contentfulPoi(url: { eq: $url }) {
      title
      type
      image {
        fluid(maxWidth: 1920, maxHeight: 1024) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      location {
        lon
        lat
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default PoiTemplate
